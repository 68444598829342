<template>
  <!-- 找回密码 -->
  <div id="house-login" class="layui-fulid findpassword password-bg">
    <div class="layui-form">
      <div v-if="!sentOk" class>
        <p>验证邮箱账号</p>
        <h3>输入邮箱账号并完成验证</h3>
        <div class="layui-input-block login">
          <input
            v-model="email"
            class="layui-input"
            lay-verify="required"
            placeholder="请输入邮箱"
            required
            type="text"
          />
        </div>
      </div>
      <div v-if="sentOk" class>
        <img class="icon" src="@/assets/img/sentok-icon.png" />
        <h4>邮件已发送</h4>
      </div>
      <button
        class="layui-btn login"
        lay-filter="user-login"
        lay-submit
        @click="sent"
      >
        {{ btntext }}
      </button>
	  <div class="end-tip">微信、手机号注册用户可扫码或验证码登录</div>
    </div>
  </div>
</template>

<script>
import { forgetPsw } from "../../service/index";
import { validEmail } from "../../utils/index";

export default {
  data() {
    return {
      email: "",
      sentOk: false,
      btntext: "发送验证邮件",
      loading: false,
    };
  },
  created() {
    let that = this;
    document.onkeydown = function (e) {
      e = window.event || e;
      if (
        that.$route.path == "/findpassword" &&
        (e.code == "Enter" || e.code == "enter")
      ) {
        //验证在找回密码界面和按得键是回车键enter
        that.sent(); //找回密码函数
      }
    };
  },
  methods: {
    async sent() {
      console.log("11111");
      if (!validEmail(this.email)) {
        this.$message.error("请输入合法邮箱");
        return;
      }
      if (this.loading) return;
      this.loading = true;
      if (this.sentOk) {
        this.$router.push("/");
        return;
      }
      const result = await forgetPsw({ email: this.email });
      console.log("----", result);
      if (result.code === 200) {
        this.sentOk = true;
        this.btntext = "跳转首页";
      } else {
        this.$message.error(result.message);
      }
      // const result = await verifyEmail();
      this.loading = false;
    },
  },
};
</script>
<style>
.password-bg {
  background: url("../../assets/img/password-bg.jpg") no-repeat center center !important;
  background-size: 100% 100% !important;
}
.end-tip{
	text-align: center;
	font-size: 13px;
	color: #999;
	margin-top: 20px;
}
</style>
